/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { openErrorToaster, clearToaster } from "services/toast.service";
import useInternetLatencyMonitor from "features/notifications/components/InternetLatencyMonitor";
import { getUser } from "../store/slices/userSessionSlice";
import PageLoader from "../components/Elements/Loaders/PageLoader/PageLoader";
import { Theme } from "../types/index";
import { useAppSelector } from "../store/hooks";
import { IUserSession } from "../types/user.interface";

/**
 * Renders children if user profile is not null
 * otherwise shows a spinner
 * @param param0
 * @returns
 */
export default function UserProfileProvider({
  children,
  theme,
}: {
  children: ({ user }: { user: IUserSession }) => React.ReactNode;
  theme: Theme;
}) {
  // fetch profile data from store
  const user = useAppSelector(getUser);

  const { checkLatency } = useInternetLatencyMonitor();
  const intervalTime = 60000; // Set interval time to 60 seconds (60000 ms)

  useEffect(() => {
    // Initial call to checkLatency
    checkLatency();

    // Set interval to call checkLatency every intervalTime
    const intervalId = setInterval(checkLatency, intervalTime);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [checkLatency]); // Run effect when checkLatency function is available

  return (
    <>
      {" "}
      {user !== null ? (
        children({ user })
      ) : (
        <PageLoader isVisible fullscreen={false} theme={theme} />
      )}
    </>
  );
}
