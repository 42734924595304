import { IHncUserSearchResult } from "types/user.interface";
import { debounce } from "lodash";
import { useMemo, useState, useEffect } from "react";
import { serverErrorHandler } from "services/server-error.service";
import { getUser } from "store/slices/userSessionSlice";
import getRandUserApi from "../api/search.api";
import { useAppSelector } from "../../../store/hooks";
import getUserProfileSearchResults from "../api/getUserProfileSearch";

/**
 * Hook for navigation bar user search
 * @param defaultValue
 * @param count
 * @returns
 */
export default function useNavSearchUsers(
  defaultValue: string,
  count?: number
) {
  // search value for username search
  const [searchValue, setSearchValue] = useState<string>(defaultValue);
  // tracks if results are being fetched
  const [isFetching, setIsFetching] = useState<boolean>(false);
  // search result list
  const [results, setResults] = useState<IHncUserSearchResult[]>([]);
  // search rand result list
  const [randResults, setRandResults] = useState<IHncUserSearchResult[]>([]);
  const [isPristine, setIsPristine] = useState<boolean>(true);
  // get current user
  const user = useAppSelector(getUser);

  // useEffect(() => {
  //   const mounted = true;

  //   /**
  //    * Makes request for random users and stores
  //    * the results in state
  //    */
  //   async function loadDataAsync(nCount: number) {
  //     try {
  //       const data = await getRandUserApi(nCount);
  //       setRandResults(data);
  //     } catch (e) {
  //       serverErrorHandler(e);
  //     }
  //   }

  //   if (mounted && count && user) loadDataAsync(count);
  // }, [count, user]);

  /**
   * Makes api request for search results and stores
   * the result in the state
   * @param val
   */
  const fetchResults = async (val: string) => {
    try {
      const response = await getUserProfileSearchResults(val, count, 1);
      setResults(response.docs);
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
      serverErrorHandler(e);
    }
  };

  /**
   * debounce search with 300ms wait time
   * Ignore continuos search calls until  timer has
   * elapsed
   *
   */
  const debouncedChangeHandler = useMemo(
    () => debounce(fetchResults, 300),

    []
  );

  const onSearch = (val: string) => {
    // show fetch spinner
    setIsFetching(true);
    setSearchValue(val);
    // field is no longer pristine
    if (isPristine) setIsPristine(false);

    // debounce async req
    debouncedChangeHandler(val);
  };

  /**
   * Clears user results and search value
   * Also hides the spinner
   */
  const clearState = () => {
    setIsFetching(false);
    setResults([]);
    setSearchValue("");
  };

  return {
    isFetching,
    results,
    searchValue,
    randResults,
    isPristine,
    onSearch,
    clearState,
  };
}
