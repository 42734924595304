import { useState, useEffect } from "react";
import { openErrorToaster, clearToaster } from "services/toast.service";

function useInternetLatencyMonitor() {
  const [connectionType, setConnectionType] = useState<string | undefined>(
    undefined
  );
  const [isLagging, setIsLagging] = useState(false);

  const checkConnectionType = () => {
    if (navigator.connection) {
      const { effectiveType } = navigator.connection;
      setConnectionType(effectiveType);

      // Define slow connection types
      if (
        effectiveType === "2g" ||
        effectiveType === "3g" ||
        effectiveType === "slow-2g"
      ) {
        openErrorToaster("Your internet is slow. Some features may lag.", 3000);
        setIsLagging(true);
      } else {
        clearToaster();
        setIsLagging(false);
      }
    }
  };

  useEffect(() => {
    // Check connection type initially
    checkConnectionType();

    // // Set up an interval to check connection type periodically
    // const interval = setInterval(checkConnectionType, 60000); // Check every minute

    // return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // Exported function to be used in other parts of the application
  const checkLatency = () => {
    checkConnectionType(); // Manually trigger a check
  };

  return { checkLatency, isLagging, connectionType };
}

export default useInternetLatencyMonitor;
